<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createProductHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add Product</vs-button>
      </div>
    </div>
    <ProductList
      :patients="patients"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getProductsList"
      @editProductParent="editProduct"
      @deleteProduct = "deleteProduct"
    />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import ProductList from "../../components/product/List";

  export default {
    components: {
      ProductList
    },
    data() {
      return {
        totalDocs: 0,
        page: 1,
        popUpDelete:false,
        patients: [],
        noDataText: 'Loading...'
      };
    },
    methods: {
      ...mapActions("product", ["fetchProducts","deleteProductServer"]),
      async getProductsList(dataTableParams) {
        let self = this;
        this.fetchProducts(dataTableParams).then(res => {
          self.patients = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total || 0;
          self.page = res.data.data.pagination.page;
          self.currentPage = res.data.data.pagination.page;
          self.noDataText = 'No Patient Available'
        });
      },
      createProductHandler(){
        this.$router.push({ name: 'AddProduct'})
      },
      editProduct(id) {
        this.$router.push({
          name: "SuperAdminEditProduct",
          params: { productId: id }
        });
      },
      async deleteProduct(data){
        let self=this;
        await this.deleteProductServer(data.id)
          .then(res => {
            self.popUpDelete = false;
            self.getProductsList(data.dataTableParams);
            this.$vs.notify({
              title: "Success",
              text: "Status Changed successfully.",
              color: "success"
            });
          })
          .catch(err => {
            self.popUpDelete = false;
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
          });
      }
    }
  };
</script>
